/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useState, useEffect } from 'react';
import {
    object, string, shape, array, func, bool, number,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import {
    Volume, VolumeX, Play, Pause,
} from 'lucide-react';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkList from '../../LinkList/LinkList';
import Panel2 from '../../Panel';
import { ifExternalUrl } from '../../NUp/helper';
import { getFeatureFlags, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import convertAlphaDecToHex from '../../../../helpers/contentstack/convertAlphaDecToHex';
import positionVideoBox from '../../../../helpers/contentstack/copyPositionFlex';
import { getOperatingSystem } from '../../../../../state/ducks/App/ducks/Config/Config-Helpers';
import isVideoFormat from '../../../../helpers/isVideoFormat';

const useStyles = makeStyles((theme) => ({
    mobileRoot: {
        display: 'block',
        margin: '0 auto',
    },
    mobileRootCMSMargin: ({ blockMargin }) => ({
        display: 'block',
        margin: blockMargin.top && blockMargin.bottom ? `${blockMargin.top}px auto ${blockMargin.bottom}px` : '0 auto',
    }),
    heroSimpleWrapper: {
        position: 'relative',
        color: theme.palette.white || '#ffffff',
        overflow: 'hidden',
        '& > div': {
            position: 'absolute',
            marginLeft: 20,
            top: 0,
            height: '100%',
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                '& > span': {
                    flexGrow: 1,
                },
            },
        },
        '@media screen and (max-width: 601px)': {
            textDecoration: 'none',
        },
        '& span > div': {
            margin: '40px 0 25px',
        },
        '& div[role=link]': {
            fontSize: 27,
            fontWeight: 'bold',
            color: theme.palette.white,
            '& u': {
                display: 'none',
            },
        },
        '& button': {
            width: 'fit-content',
            '& > span': {
                padding: '8px 13px',
                fontWeight: 'bold',
                '& .MuiButton-endIcon': {
                    display: 'none',
                },
            },
        },
    },
    withPromoText: {
        color: theme.palette.globalBannerMainTextColor || '#2f2f2f',
        '& > div': {
            '& > div': {
                height: '90%',
            },
        },
    },
    heroFullWidthWrapper: {
        position: 'relative',
        display: 'block',
        textAlign: 'center',
        color: theme.palette.white || '#ffffff',
        overflow: 'hidden',
        '& > div': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '98%',
            },
        },
        '@media screen and (max-width: 601px)': {
            textDecoration: 'none',
        },
        '& span': {
            flexGrow: 1,
        },
        '& span > div': {
        },
        '& button': {
            margin: 'o auto',
        },
        '& button > span': {
            padding: '5px 8px',
            fontWeight: 'bold',
            '& .MuiButton-endIcon': {
                display: 'none',
            },
        },
    },
    heroSimpleBannerRoot: {
        display: 'block',
        margin: '0 auto',
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: '100%',
            height: '100%',
            display: 'inline-block',
        },
    },
    heroHeight200: {
        height: 200,
        '& img': {
            height: '100%',
        },
    },
    heroHeight300: {
        height: 300,
        '& img': {
            height: '100%',
        },
    },
    heroHeight400: {
        height: 400,
        '& img': {
            height: '100%',
        },
    },
    heroHeight600: {
        height: 600,
        '& img': {
            height: '100%',
        },
    },
    bannerImage: {
        width: '100%',
        height: 'auto',
    },
    heroSimpleBanner: {
        width: '100%',
        height: 'auto',
        textDecoration: 'none',
    },
    heroSimpleBannerWithoutPromo: {
        '&:after': {
            backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 13.4%, rgba(0, 0, 0, 0.375) 42.44%, rgba(0, 0, 0, 0) 68.26%)',
        },
    },
    heroSimpleBannerWithPromo: {
        '&:after': {
            backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 13.4%, rgba(0, 0, 0, 0.375) 42.44%, rgba(0, 0, 0, 0) 68.26%)',
        },
    },
    heading: {
        textAlign: 'center',
        fontSize: '1.7em',
        margin: '1em auto 0',
        fontWeight: '400',
    },
    shopby: {
        textAlign: 'center',
        margin: '.3em auto 1em auto',
        fontSize: '1.2em',
    },

    heroVideoWrapper: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
    },
    heroVideoContainer: {
        position: 'relative',
    },
    heroVideoHeadline: ({ copyPosition }) => ({
        width: '90%',
        textAlign: 'center',
        position: 'absolute',
        margin: '0 auto',
        top: copyPosition?.top || '50%',
        right: copyPosition?.right || '50%',
        bottom: copyPosition?.bottom || '',
        left: copyPosition?.left || '',
        transform: 'translate(50%, -50%)',
        '& img': {
            width: '55% !important',
        },

    }),
    heroVideoHeadlineFood: {
        color: theme.palette.common?.black || '#000000',
        padding: '0 10px',
    },
    heroCopyAndImgContainer: {
        position: 'relative',
    },
    heroCopyAndImgHeadline: ({ copyPosition }) => ({
        width: '90%',
        textAlign: 'center',
        position: 'absolute',
        margin: '0 auto',
        top: copyPosition?.top || '50%',
        right: copyPosition?.right || '51%',
        bottom: copyPosition?.bottom || '',
        left: copyPosition?.left || '',
        transform: 'translate(50%, -50%)',
    }),
    absoluteContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
    },
    relative: {
        position: 'relative',
    },
    mobileHeightPixel: ({ mobile_height_pixel }) => ({
        height: mobile_height_pixel || '',
    }),
    objectFit: ({ mobile_height_pixel })  => ({
        objectFit: 'fill',
        height: mobile_height_pixel || 'auto',
    }),
    videoBoxFood: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1400px',
        position: 'relative',
    },
    videoControlPause: {
        border: 'solid 5px #ffffff55',
        borderWidth: '0 3px',
        height: '10px',
        width: '8px',
        '&:hover': {
            borderColor: '#ffffff',
        },
    },
    departmentWithHeading: ({ image_mobile }) => ({
        padding: '1em 30% 1em 1em',
        backgroundImage: `url(${image_mobile?.url})`,
        backgroundSize: 'cover',
        minHeight: '180px',
        '& a': {
            textDecoration: 'none',
        },
        '& h1': {
            textWrap: 'balance',
            margin: '0 0 1em 0',
            color: '#2f2f2f',
        },
        '& .messageCopy:not(:has(h1))': { display: 'none' },
    }),
    playPoster: {
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99999',
        position: 'absolute',
        background: '#00000055',
        cursor: 'pointer',
        '&>div': {
            display: 'flex',
            height: 'inherit',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& svg': {
            stroke: '#ffffff',
            transition: 'all .3s',
            height: '90px',
            width: '90px',
            display: 'block',
        },
    },
    videoControls: {
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        zIndex: '1',
        display: 'flex',
        '& div': {
            cursor: 'pointer',
        },
        '& > div > *': {
            width: '50px',
            height: '50px',
        },
    },
    videoControlPlay: {
        opacity: 0,
        marginRight: '.5em',
        width: 0,
        height: 0,
        borderWidth: '10px 0 10px 12px',
        borderColor: 'transparent transparent transparent #ffffff',
        borderStyle: 'solid',
    },
    videoControlVideo: {
        '& svg': {
            stroke: '#ffffff9e',
        },
    },
    videoControlAudio: {
        '& svg': {
            stroke: '#ffffff9e',
        },
    },
    show: {
        display: 'block',
    },
    hide: {
        display: 'none',
    },
}));

// Check if banner contains a Link List reference
const linkListIndex = (data) => {
    let i;
    let index = -1;
    for (i = 0; i < data.length; i += 1) {
        if ('link_list' in data[i]) {
            index = i;
        }
    }
    return index;
};

const HeroSingleMobile = ({
    image_mobile,
    message_collection,
    message_styling,
    overlays,
    linking,
    trackEvent,
    mobile_department_heading,
    passportOpenSideBar,
    featureFlags,
    presentation_type,
    mobile_height,
    is_mobile_copy,
    container_attributes,
    blockMargin,
    messagePositioning,
    imageOpacity,
    mobile_height_pixel,
    videoPoster,
    videoData,
    video,
    trackCustomClickstreamEventByType,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    const isTabWidth = useMediaQuery('(max-width: 1025px)');
    const containerAttributePosition = container_attributes.find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'copy_position';
    });
    const backgroundColor = (container_attributes || []).find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'background_color';
    });
    const copyPosition = containerAttributePosition?.copy_position || {};
    const classes = useStyles({
        copyPosition, blockMargin, mobile_height_pixel, image_mobile,
    });

    const mobilePreprocessedUrl = image_mobile?.content_type !== 'video/mp4' ? image_mobile?.url : '';
    const alt = image_mobile?.description || image_mobile?.title || 'Hero Image';
    const dimensions = image_mobile?.dimension || {};

    let hasDetailsLink = false;
    for (let index = 0; index < message_collection.length; index += 1) {
        if (Object.prototype.hasOwnProperty.call(message_collection[index], 'details_link')) {
            hasDetailsLink = true;
            break;
        }
    }
    let mobileHeightClass;
    switch (mobile_height) {
        case '200':
            mobileHeightClass = classes.heroHeight200;
            break;
        case '300':
            mobileHeightClass = classes.heroHeight300;
            break;
        case '400':
            mobileHeightClass = classes.heroHeight400;
            break;
        case '600':
            mobileHeightClass = classes.heroHeight600;
            break;
        default:
            mobileHeightClass = '';
    }
    if (mobile_height_pixel) {
        mobileHeightClass = classes.mobileHeightPixel;
    }
    const hasDepartmentHeading = () => {
        if (mobile_department_heading) {
            return (
                <>
                    <Typography variant="h2" className={classes.heading}>{mobile_department_heading}</Typography>
                    <Typography variant="h5" className={classes.shopby}>Shop by Category:</Typography>
                </>
            );
        }
        return <></>;
    };
    const createVideoID = () => {
        const src = video?.desktop?.mp4?.url || image_mobile?.url || 'Video';
        /**
         * turn https://assets.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt39521815bb9e56cb/6511b146474d4d76f24f2c43/23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1.mp4
         * into 23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1
         * */
        const id = src.split('/')?.pop()?.split('.')[0] || 'ComponentVideo';
        return id;
    };
    const renderHeroSimple = (position) => {
        let responseMessage = <></>;
        if (message_collection.length > 0) {
            responseMessage = (
                <Panel2
                    data={{
                        message_collection,
                        isMobileCopy: is_mobile_copy,
                    }}
                    styleData={{ position }}
                />
            );
        }
        return responseMessage;
    };

    if (image_mobile) {
        const {
            link = {}, tracking_event_action = '', tracking_event_label = '', tracking_event_category = '',
        } = linking || {};
        const { href = '', title = '' } = link;

        useEffect(() => {
            trackCustomClickstreamEventByType('Impression');
        }, []);

        const handleClick = (e) => {
            if (title === 'Celebrations Passport'
                && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
                e.preventDefault();
                passportOpenSideBar();
            }
            trackCustomClickstreamEventByType('Click');
        };

        let response = <></>;
        if (isVideoFormat(video?.mobile?.webm?.content_type || image_mobile?.content_type)) {
            const operatingSystem = getOperatingSystem().toLowerCase();
            const legacyCMSControl = !!(videoData.disable_autoplay === undefined && videoData.has_audio === undefined);
            const videoRef = useRef(null);
            const panelDataVideo = {
                message_collection,
                overlays,
                isMobileCopy: is_mobile_copy,
            };

            const disableAutoPlay = () => {
                if (legacyCMSControl) return false;
                if (videoData.disable_autoplay === undefined) return false;
                if (!videoData.disable_autoplay) return false;
                return true;
            };
            const audioValue = () => {
                if (videoData.has_audio === undefined) return true;
                if (videoData.disable_autoplay) return false;
                if (!videoData.has_audio) return false;
                return true;
            };
            /*
                Video play/pause/audio controls
            */
            const [videoPaused, toggleVideoPlayback] = useState(disableAutoPlay());
            const [audioMute, toggleAudioMute] = useState(audioValue());
            const [playPosterHide, hidePlayPoster] = useState(false);

            const poster = () => {
                if (isTabWidth && videoPoster?.tablet?.url) {
                    return `${videoPoster?.tablet?.url}?auto=webp`;
                }
                if (videoPoster?.mobile?.url) {
                    return `${videoPoster?.mobile?.url}?auto=webp`;
                }
                return '';
            };

            const toggleAudio = () => {
                toggleAudioMute(!audioMute);
                videoRef.current.muted = !audioMute;
            };

            const toggleVideo = () => {
                toggleVideoPlayback(!videoPaused);
                hidePlayPoster(true);
                if (!playPosterHide) videoRef.current.muted = false;
                if (videoPaused) {
                    videoRef.current.play();
                    return;
                }
                videoRef.current.pause();
            };

            const bgColor = backgroundColor?.background_color?.color?.color || null;
            const positionStyling = positionVideoBox(messagePositioning);
            const videoID = `video_${createVideoID()}`;
            const showPlayPoster = disableAutoPlay() && !audioMute && !playPosterHide && !legacyCMSControl;

            const playPosterPartial = () => (
                <div
                    onClick={toggleVideo}
                    onKeyDown={toggleVideo}
                    role="button"
                    tabIndex="0"
                    aria-controls={videoID}
                    aria-pressed="false"
                    aria-label="Toggle Video"
                    className={`${classes.playPoster} ${videoPaused && disableAutoPlay() ? classes.show : classes.hide}`}
                >
                    <div className={classes.initPlaySVGWrapper}><Play mute className={videoPaused ? classes.show : classes.hide} /></div>
                </div>
            );

            response = (
                <div className={classes.videoBoxFood}>
                    { showPlayPoster && playPosterPartial() }
                    <div className={classes.videoControls}>
                        <div className={classes.videoControlVideo} onClick={toggleVideo} onKeyDown={toggleVideo} role="button" tabIndex="0" aria-controls={videoID} aria-pressed="false" aria-label="Toggle Video">
                            <Play mute className={videoPaused ? classes.show : classes.hide} />
                            <Pause unmute className={videoPaused ? classes.hide : classes.show} />
                        </div>
                        {videoData.has_audio && (
                            <div className={classes.videoControlAudio} onClick={toggleAudio} onKeyDown={toggleAudio} role="button" tabIndex="0" aria-controls={videoID} aria-pressed="false" aria-label="Control Audio">
                                <Volume mute className={audioMute ? classes.show : classes.hide} />
                                <VolumeX unmute className={audioMute ? classes.hide : classes.show} />
                            </div>
                        )}
                    </div>
                    <Link
                        data-ga-category={tracking_event_category || ''}
                        data-ga-action={tracking_event_action || ''}
                        data-ga-label={tracking_event_label || ''}
                        onClick={(e) => {
                            handleClick(e);
                            trackEvent({
                                eventCategory: tracking_event_category || '',
                                eventAction: tracking_event_action || '',
                                eventLabel: tracking_event_label || '',
                            });
                            ifExternalUrl(e, href, true);
                        }}
                        to={href}
                        aria-hidden="true"
                        tabIndex="-1"
                        title={title}
                        className={classes.heroVideoWrapper}
                    >
                        <div className={`${classes.heroVideoContainer} ${classes.relative}`}>
                            <video
                                ref={videoRef}
                                id={`video_${createVideoID()}`}
                                poster={poster()}
                                data-testid="video-box-mobile"
                                autoPlay={!videoPaused}
                                loop
                                muted={audioMute}
                                defaultMuted
                                width="100%"
                                height="100%"
                                playsInline
                                preLoad="auto"
                                className={classes.objectFit}
                            >
                                <source src={video?.mobile?.webm?.url || image_mobile?.url} type={video?.mobile?.webm?.content_type || image_mobile?.content_type} preload="metadata" />
                                <source src={video?.mobile?.mp4?.url || image_mobile?.url} type={video?.mobile?.mp4?.content_type || image_mobile?.content_type} preload="metadata" codecs={operatingSystem === 'ios' ? 'avc1.42E01E, mp4a.40.2' : ''} />
                                <track kind="captions" />
                            </video>
                            <div
                                style={{
                                    ...positionStyling,
                                }}
                                className={classes.absoluteContainer}

                            >
                                <div
                                    style={{ background: bgColor ? convertAlphaDecToHex(bgColor, imageOpacity) : 'transparent' }}
                                    className={presentationFamily === 'food' ? classes.heroVideoHeadlineFood : classes.heroVideoHeadline}
                                    data-testid="hero-single-mobile-video"
                                >
                                    <Panel2
                                        data={panelDataVideo}
                                        styleData={message_styling}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            );
        } else if (is_mobile_copy && presentation_type === 'Fixed Width' && presentationFamily === 'food') { // presentationFamily logic for now just to sandbox this for real world testing.
            response = (
                <div className={classes.departmentWithHeading}>
                    <Link
                        data-ga-category={tracking_event_category || ''}
                        data-ga-action={tracking_event_action || ''}
                        data-ga-label={tracking_event_label || ''}
                        onClick={(e) => {
                            handleClick(e);
                            trackEvent({
                                eventCategory: tracking_event_category || '',
                                eventAction: tracking_event_action || '',
                                eventLabel: tracking_event_label || '',
                            });
                            ifExternalUrl(e, href, true);
                        }}
                        to={href}
                        aria-hidden="true"
                        tabIndex="-1"
                        title={title}
                        className={classes.mobileRoot}
                        style={!message_collection?.length ? { fontSize: '0' } : {}}
                    >
                        <Panel2
                            data={{
                                message_collection,
                                isMobileCopy: is_mobile_copy,
                            }}
                        />
                    </Link>
                </div>
            );
        } else if (presentation_type?.indexOf('Hero Simple') !== -1) { // 600px banner with and without promo - height specified from CS
            response = (
                <div className={`${classes.heroSimpleWrapper} ${hasDetailsLink && classes.withPromoText} ${mobileHeightClass}`}>
                    <Link
                        data-ga-category={tracking_event_category || ''}
                        data-ga-action={tracking_event_action || ''}
                        data-ga-label={tracking_event_label || ''}
                        onClick={(e) => {
                            handleClick(e);
                            trackEvent({
                                eventCategory: tracking_event_category || '',
                                eventAction: tracking_event_action || '',
                                eventLabel: tracking_event_label || '',
                            });
                            ifExternalUrl(e, href, true);
                        }}
                        to={href}
                        aria-hidden="true"
                        tabIndex="-1"
                        title={title}
                        className={`${classes.heroSimpleBannerRoot} ${hasDetailsLink ? classes.heroSimpleBannerWithPromo : classes.heroSimpleBannerWithoutPromo} ${mobileHeightClass}`}
                    >
                        <ResponsiveImage
                            className={classes.heroSimpleBanner}
                            path={mobilePreprocessedUrl}
                            alt={alt}
                            dimensions={dimensions}
                        />
                    </Link>
                    { renderHeroSimple('absolute')}
                </div>
            );
        } else if (presentation_type?.indexOf('Full Width') !== -1) { // Banners with copy and CTA - height specified from CS
            response = (
                <Link
                    data-ga-category={tracking_event_category || ''}
                    data-ga-action={tracking_event_action || ''}
                    data-ga-label={tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e);
                        trackEvent({
                            eventCategory: tracking_event_category || '',
                            eventAction: tracking_event_action || '',
                            eventLabel: tracking_event_label || '',
                        });
                        ifExternalUrl(e, href, true);
                    }}
                    to={href}
                    aria-hidden="true"
                    tabIndex="-1"
                    title={title}
                    className={`${classes.heroFullWidthWrapper} ${mobileHeightClass}`}
                >
                    { renderHeroSimple('absolute')}
                    {mobilePreprocessedUrl  && (
                        <ResponsiveImage
                            className={classes.bannerImage}
                            path={mobilePreprocessedUrl}
                            alt={alt}
                            dimensions={dimensions}
                        />
                    )}
                </Link>
            );
        }   else if (message_collection.length > 0) {
            response = (
                <Link
                    data-ga-category={tracking_event_category || ''}
                    data-ga-action={tracking_event_action || ''}
                    data-ga-label={tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e);
                        trackEvent({
                            eventCategory: tracking_event_category || '',
                            eventAction: tracking_event_action || '',
                            eventLabel: tracking_event_label || '',
                        });
                        ifExternalUrl(e, href, true);
                    }}
                    to={href}
                    aria-hidden="true"
                    tabIndex="-1"
                    title={title}
                    className={classes.mobileRootCMSMargin}
                    style={!message_collection?.length ? { fontSize: '0' } : {}}
                >
                    <div className={classes.heroCopyAndImgContainer}>
                        {mobilePreprocessedUrl && (
                            <ResponsiveImage
                                className={classes.bannerImage}
                                path={mobilePreprocessedUrl}
                                alt={alt}
                                dimensions={dimensions}
                            />
                        )}
                    </div>
                </Link>
            );
        } else {
            response = (
                <Link
                    data-ga-category={tracking_event_category || ''}
                    data-ga-action={tracking_event_action || ''}
                    data-ga-label={tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e);
                        trackEvent({
                            eventCategory: tracking_event_category || '',
                            eventAction: tracking_event_action || '',
                            eventLabel: tracking_event_label || '',
                        });
                        ifExternalUrl(e, href, true);
                    }}
                    to={href}
                    aria-hidden="true"
                    tabIndex="-1"
                    title={title}
                    className={classes.mobileRoot}
                    style={!message_collection?.length ? { fontSize: '0' } : {}}
                >
                    {mobilePreprocessedUrl &&  (
                        <ResponsiveImage
                            className={classes.bannerImage}
                            path={mobilePreprocessedUrl}
                            alt={alt}
                            dimensions={dimensions}
                        />
                    )}
                </Link>
            );
        }
        return response;
    }

    if (message_collection.length > 0) {
        const linkListIndexOf = linkListIndex(message_collection);
        if (linkListIndexOf !== -1) {
            return (
                <>
                    {hasDepartmentHeading()}
                    <LinkList data={message_collection[linkListIndexOf]} />
                </>
            );
        }
        return (
            <Panel2
                data={{
                    message_collection,
                    isMobileCopy: is_mobile_copy,
                }}
            />
        );
    }
    if (mobile_department_heading) return hasDepartmentHeading();
    return null;
};

HeroSingleMobile.propTypes = {
    message_collection: array.isRequired,
    message_styling: array.isRequired,
    overlays: array.isRequired,
    linking: shape({
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
    image_mobile: shape({
        url: string,
        content_type: string,
    }),
    trackEvent: func.isRequired,
    mobile_department_heading: string,
    passportOpenSideBar: func,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    presentation_type: string,
    mobile_height: string,
    is_mobile_copy: bool,
    container_attributes: array.isRequired,
    blockMargin: object,
    messagePositioning: string,
    imageOpacity: number,
    mobile_height_pixel: number,
    videoPoster: shape({
        desktop: shape({
            url: string,
        }),
        tablet: shape({
            url: string,
        }),
        mobile: shape({
            url: string,
        }),
    }),
    videoData: object,
    video: object,
    trackCustomClickstreamEventByType: func,
};

HeroSingleMobile.defaultProps = {
    mobile_department_heading: '',
    videoPoster: {},
    image_mobile: {
        url: '',
        content_type: '',
    },
    passportOpenSideBar: () => {},
    featureFlags: {},
    presentation_type: 'Full Width',
    mobile_height: '',
    is_mobile_copy: false,
    blockMargin: {},
    messagePositioning: null,
    imageOpacity: null,
    mobile_height_pixel: '',
    videoData: {},
    video: {},
    trackCustomClickstreamEventByType: () => { },
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeroSingleMobile);
